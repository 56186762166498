// Определение основных цветов
$primary-color: #0056b3;
$secondary-color: #f8f9fa;
$error-color: #dc3545;
$success-color: #28a745;
$info-color: #007bff !default;

// Шрифты
$base-font-family: "Arial", sans-serif;
$base-font-size: 16px;

// Отступы
$base-spacing: 16px;
