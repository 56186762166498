@use "sass:math";
@import "../../styles/variables";

.formContainer {
  max-width: 600px;
  margin: 0 auto;
  background: $secondary-color;
  padding: math.div($base-spacing, 3);
  border-radius: math.div($base-spacing, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.formTitle {
  color: $primary-color;
  margin-bottom: math.div($base-spacing, 2);
}

.formError {
  color: $error-color;
  margin-bottom: math.div($base-spacing, 1.25);
}

.formInput {
  width: 100%;
  padding: math.div($base-spacing, 1);
  margin-top: math.div($base-spacing, 1);
  border: 1px solid darken($primary-color, 20%);
  border-radius: math.div($base-spacing, 2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: $primary-color;
    box-shadow: 0 0 5px rgba($primary-color, 0.5);
    outline: none;
  }
}

.formButton {
  background-color: $primary-color;
  color: white;
  padding: math.div($base-spacing, 1.5);
  border: none;
  border-radius: math.div($base-spacing, 1);
  cursor: pointer;
  margin-top: math.div($base-spacing, 1.25);
  transition: background 0.3s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

// Стили для планшетной версии
@media screen and (max-width: 1024px) {
  .formContainer {
    max-width: 80%;
  }

  .formTitle {
    font-size: math.div($base-font-size, 1.25);
  }

  .formInput {
    padding: math.div($base-spacing, 0.75);
    margin-bottom: math.div($base-spacing, 1);
  }

  .formButton {
    padding: math.div($base-spacing, 1);
    font-size: math.div($base-font-size, 0.875);
  }
}

// Стили для мобильной версии
@media screen and (max-width: 768px) {
  .formContainer {
    max-width: 90%;
  }

  .formTitle {
    font-size: math.div($base-font-size, 1.125);
    margin-bottom: math.div($base-spacing, 1.875);
  }

  .formInput {
    padding: math.div($base-spacing, 0.75);
    margin-bottom: math.div($base-spacing, 1);
    font-size: math.div($base-font-size, 0.875);
  }

  .formButton {
    padding: math.div($base-spacing, 1);
    font-size: math.div($base-font-size, 0.75);
  }
}
