@use "sass:math"; // Использование модуля math для выполнения делений
@import "../../styles/variables"; // Импорт переменных для использования в стилях

.employeePageContainer {
  max-width: 1200px;
  margin: 0 auto;
  // padding: math.div($base-spacing, 2.5);
  background-color: $secondary-color;
  border: 1px solid darken($secondary-color, 10%);
  border-radius: math.div($base-spacing, 1); // Исправлено деление
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: $base-font-family;
  text-align: center;
}

h1 {
  color: $primary-color;
  margin-bottom: math.div($base-spacing, 2); // Исправлено деление
}

.catamaransContainer {
  margin-top: math.div($base-spacing, 2); // Исправлено деление
  // padding: math.div($base-spacing, 2);
  background-color: white;
  border-radius: math.div($base-spacing, 1); // Исправлено деление
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

// Стили для планшетной версии
@media screen and (max-width: 1024px) {
  .employeePageContainer {
    max-width: 90%;
    // padding: math.div($base-spacing, 2);
  }

  // .catamaransContainer {
  //   padding: math.div($base-spacing, 1.5);
  // }
}

// Стили для мобильной версии
@media screen and (max-width: 768px) {
  .employeePageContainer {
    max-width: 95%;
    // padding: math.div($base-spacing, 1.5);
  }

  // .catamaransContainer {
  //   padding: math.div($base-spacing, 1);
  // }
}
