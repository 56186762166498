@use "sass:math";
@import "../../styles/variables";

// Определение переменной $info-color, если она отсутствует
$info-color: #007bff !default; // Синий цвет

.rentalReportsContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: math.div($base-spacing, 2);
  border: 2px solid darken($primary-color, 15%);
  border-radius: $base-spacing;
  background-color: $secondary-color;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  font-family: $base-font-family;
}

.reportsTitle {
  font-size: $base-font-size;
  color: darken($primary-color, 10%);
  margin-bottom: $base-spacing;
}

.dateInput,
.textInput {
  width: 100%;
  margin-bottom: $base-spacing;
  padding: $base-spacing;
  border: 2px solid darken($primary-color, 20%);
  border-radius: $base-spacing;
  font-size: math.div($base-font-size, 1.25);
}

.fetchButton {
  width: 100%;
  padding: $base-spacing;
  background-color: $success-color;
  color: white;
  border: none;
  border-radius: $base-spacing;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($success-color, 10%);
  }
}

.errorMessage {
  color: $error-color;
  padding: $base-spacing;
  margin-top: $base-spacing;
  font-weight: bold;
}

.rentalReportsContainer ul {
  list-style: none;
  padding: 0;
  margin-top: $base-spacing;
}

.rentalReportsContainer li {
  background-color: white;
  border-radius: $base-spacing;
  margin-bottom: $base-spacing;
  padding: $base-spacing;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rentalReportsContainer h2 {
  color: darken($primary-color, 20%);
  margin-bottom: $base-spacing;
}

.rentalReportsContainer strong {
  color: darken($primary-color, 20%);
}

.rentalReportsContainer div {
  margin-top: $base-spacing;
  padding-left: $base-spacing;
}

// Вложенный список для деталей проката
.rentalReportsContainer ul ul {
  padding-left: math.div($base-spacing, 2);
}

.rentalReportsContainer ul ul li {
  padding: math.div($base-spacing, 2);
  background-color: lighten($secondary-color, 5%);
  border-radius: math.div($base-spacing, 4);
  margin-bottom: math.div($base-spacing, 4);
  color: darken($primary-color, 20%);
  line-height: 1.5;
}

.startTime,
.duration {
  color: $success-color; // Зелёный цвет для текста
  font-weight: bold;
}

.durationExceeded {
  color: $error-color; // Красный цвет для текста, если продолжительность превышает 30 минут
}

// Стили для текста, если отчеты не найдены
.rentalReportsContainer p {
  color: $error-color;
  text-align: center;
  padding: math.div($base-spacing, 2);
}

.returnComment {
  color: $info-color; // Синий цвет для комментария к возврату
  font-weight: bold;
}

// Стили для планшетной версии
@media screen and (max-width: 1024px) {
  .rentalReportsContainer {
    max-width: 80%;
  }

  .reportsTitle {
    font-size: math.div($base-font-size, 1.25);
  }

  .dateInput,
  .textInput,
  .fetchButton {
    font-size: math.div($base-font-size, 0.875);
  }
}

// Стили для мобильной версии
@media screen and (max-width: 768px) {
  .rentalReportsContainer {
    max-width: 90%;
    padding: math.div($base-spacing, 1.5);
  }

  .reportsTitle {
    font-size: math.div($base-font-size, 1);
  }

  .dateInput,
  .textInput,
  .fetchButton {
    font-size: math.div($base-font-size, 0.75);
  }
}
