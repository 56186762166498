@use "sass:math";
@import "../../styles/variables";

.registerFormContainer {
  max-width: 400px;
  margin: 0 auto;
  padding: math.div($base-spacing, 2.5);
  border: 1px solid darken($secondary-color, 10%);
  border-radius: math.div($base-spacing, 1);
  background-color: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: $base-font-family;
}

.errorMessage {
  color: $error-color;
  margin-bottom: math.div($base-spacing, 1.25);
}

.successMessage {
  color: green;
  margin-bottom: math.div($base-spacing, 1.25);
}

.inputField {
  width: 100%;
  margin-bottom: math.div($base-spacing, 1.25);
  padding: math.div($base-spacing, 1);
  font-size: math.div($base-font-size, 0.875);
  border: 1px solid darken($primary-color, 20%);
  border-radius: math.div($base-spacing, 2);
}

.submitButton {
  width: 100%;
  padding: math.div($base-spacing, 1.25);
  font-size: $base-font-size;
  background-color: $primary-color;
  color: #fff;
  border: none;
  border-radius: math.div($base-spacing, 2);
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

@media screen and (max-width: 1024px) {
  .registerFormContainer {
    max-width: 80%;
  }

  .inputField {
    font-size: math.div($base-font-size, 0.875);
    padding: math.div($base-spacing, 0.75);
  }

  .submitButton {
    font-size: math.div($base-font-size, 0.875);
    padding: math.div($base-spacing, 1);
  }
}

@media screen and (max-width: 768px) {
  .registerFormContainer {
    max-width: 90%;
  }

  .inputField {
    font-size: math.div($base-font-size, 0.875);
    padding: math.div($base-spacing, 0.5);
  }

  .submitButton {
    font-size: math.div($base-font-size, 0.75);
    padding: math.div($base-spacing, 0.75);
  }
}
