@use "sass:math";
@import "../../styles/variables";

.catamaranContainer {
  max-width: 800px;
  margin: 0 auto;
  background-color: $secondary-color;
  border: 2px solid darken($secondary-color, 10%);
  border-radius: $base-spacing;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1);
  font-family: $base-font-family;
}

.header {
  text-align: center;
  position: relative;
}

h1 {
  text-align: center;
  color: $primary-color;
  margin-bottom: $base-spacing;
}

.error {
  color: $error-color;
  text-align: center;
  margin-bottom: $base-spacing;
}

.timeLimitButtons {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  gap: $base-spacing;

  button {
    padding: 3px;
    font-size: math.div($base-font-size, 1);
    color: white;
    border: none;
    border-radius: $base-spacing;
    cursor: pointer;
    background-color: $primary-color;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($primary-color, 10%);
    }

    &:disabled {
      background-color: lighten($primary-color, 40%);
      cursor: not-allowed;
    }
  }

  .active {
    background-color: darken($primary-color, 20%);
  }
}

.catamaranList {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6px;
  padding: 0;
  list-style: none;
}

.catamaranItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid darken($secondary-color, 10%);
  border-radius: $base-spacing;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .catamaranName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: math.div($base-font-size, 0.75);
    color: darken($primary-color, 20%);
    font-weight: bold;
    padding: 0 $base-spacing;
  }

  .counterContainer {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .counterButton {
      width: 30px;
      height: 30px;
      font-size: 20px;
      text-align: center;
      line-height: 30px;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      cursor: pointer;
    }

    .counterValue {
      width: 30px;
      text-align: center;
      line-height: 30px;
      font-size: 18px;
    }
  }
}

.buttonRow {
  display: flex;
  justify-content: flex-start;
  gap: $base-spacing;
  padding: 0 $base-spacing;

  button {
    flex: 1 1 auto;
    font-size: math.div($base-font-size, 0.875);
    color: white;
    border: none;
    border-radius: $base-spacing;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: darken($success-color, 10%);
    }

    &:disabled {
      background-color: lighten($primary-color, 40%);
      cursor: not-allowed;
    }
  }

  .startRentalButton {
    background-color: $success-color;
  }

  .endRentalButton {
    background-color: $error-color;

    &:hover {
      background-color: darken($error-color, 10%);
    }
    &:disabled {
      background-color: lighten($primary-color, 40%);
      cursor: not-allowed;
    }
  }
}

.catamaranItem button {
  padding: 3px;
  font-size: math.div($base-font-size, 0.875);
  color: white;
  border: none;
  border-radius: $base-spacing;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: $primary-color;

  &:hover {
    background-color: darken($primary-color, 10%);
  }

  &:not(:last-child) {
    background-color: $success-color;

    &:hover {
      background-color: darken($success-color, 10%);
    }
  }

  &:disabled {
    background-color: lighten($primary-color, 40%);
    cursor: not-allowed;
  }
}

.rentalTime {
  font-size: math.div($base-font-size, 0.75);
  color: $success-color;
  font-weight: bold;
  background-color: lighten($secondary-color, 10%);
  border-radius: $base-spacing;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 $base-spacing;
}

.rentalTimeExpired {
  font-size: math.div($base-font-size, 0.75);
  color: $error-color;
  font-weight: bold;
  background-color: lighten($error-color, 30%);
  border-radius: $base-spacing;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 $base-spacing;
}

.catamaranItem div {
  margin-top: 3px;
  font-size: math.div($base-font-size, 0.875);
  color: darken($primary-color, 20%);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: white;
  padding: $base-spacing;
  border-radius: $base-spacing;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 100%;
}

.modalTextarea {
  width: 100%;
  height: 100px;
  margin-bottom: $base-spacing;
  padding: $base-spacing;
  font-size: math.div($base-font-size, 0.675);
  border: 1px solid $primary-color;
  border-radius: $base-spacing;
  resize: none;
}

.modalSubmitButton,
.modalCloseButton {
  width: 100%;
  padding: math.div($base-spacing, 1.5);
  font-size: math.div($base-font-size, 0.875);
  color: white;
  border: none;
  border-radius: $base-spacing;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

.modalSubmitButton {
  background-color: $success-color;
}

.modalCloseButton {
  background-color: $error-color;
  margin-top: $base-spacing;

  &:hover {
    background-color: darken($error-color, 10%);
  }
}

.rentalTimeContainer {
  margin-bottom: 5px;
}

// Стили для планшетной версии
@media screen and (max-width: 1024px) {
  .catamaranContainer {
    max-width: 100%;
  }

  .catamaranList {
    grid-template-columns: repeat(3, 1fr);
  }

  .catamaranItem button {
    font-size: $base-font-size * 0.875;
  }
}

// Стили для мобильной версии
@media screen and (max-width: 768px) {
  .catamaranContainer {
    max-width: 100%;
  }

  .catamaranList {
    grid-template-columns: repeat(2, 1fr);
  }

  .catamaranItem span,
  .catamaranItem div {
    font-size: math.div($base-font-size, 0.75);
  }

  .catamaranItem button {
    font-size: math.div($base-font-size, 0.75);
    padding: math.div($base-spacing, 2);
  }
}
