@use "sass:math";
@import "../../styles/variables";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modalContent {
  background: white;
  padding: math.div($base-spacing, 2.5);
  border-radius: math.div($base-spacing, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 90%;
  max-width: 500px;
}

.closeButton {
  position: absolute;
  top: math.div($base-spacing, 1.25);
  right: math.div($base-spacing, 1.25);
  font-size: math.div($base-font-size, 1.5);
  border: none;
  background: none;
  cursor: pointer;
}

.managerPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: math.div($base-spacing, 2);
}

.createButton {
  margin: math.div($base-spacing, 2.5);
  padding: math.div($base-spacing, 1.25) math.div($base-spacing, 2.5);
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: math.div($base-spacing, 2);
  cursor: pointer;

  &:hover {
    background-color: darken($primary-color, 10%);
  }
}

// Стили для планшетной версии
@media screen and (max-width: 1024px) {
  .modalContent {
    padding: math.div($base-spacing, 2);
  }

  .closeButton {
    font-size: math.div($base-font-size, 1.25);
  }

  .createButton {
    padding: math.div($base-spacing, 1) math.div($base-spacing, 2);
    font-size: math.div($base-font-size, 0.875);
  }
}

// Стили для мобильной версии
@media screen and (max-width: 768px) {
  .modalContent {
    padding: math.div($base-spacing, 1.5);
  }

  .closeButton {
    font-size: math.div($base-font-size, 1.125);
  }

  .createButton {
    padding: math.div($base-spacing, 0.75) math.div($base-spacing, 1.5);
    font-size: math.div($base-font-size, 0.75);
  }
}
