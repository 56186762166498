@use "sass:math";
@import "../../styles/variables";

.employeeSelectorContainer {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: math.div($base-spacing, 2.5);
  border: 1px solid darken($secondary-color, 10%);
  border-radius: math.div($base-spacing, 1);
  background-color: $secondary-color;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.employeeSelectorLabel {
  display: block;
  margin-bottom: math.div($base-spacing, 1);
  font-size: math.div($base-font-size, 0.725);
  font-weight: bold;
  color: $primary-color;
}

.selectorWrapper {
  display: flex;
  align-items: center;
}

.employeeSelectorSelect {
  flex: 1;
  padding: math.div($base-spacing, 0.75);
  border-radius: math.div($base-spacing, 2);
  border: 1px solid darken($primary-color, 20%);
  background-color: white;
  color: darken($primary-color, 50%);
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.3s;
  font-size: math.div($base-font-size, 0.725);

  &:hover,
  &:focus {
    border-color: $primary-color;
    box-shadow: 0 0 5px rgba($primary-color, 0.5);
    outline: none;
  }
}

.deleteButton {
  margin-left: math.div($base-spacing, 1);
  padding: math.div($base-spacing, 0.65) math.div($base-spacing, 1.7);
  background-color: $error-color;
  color: white;
  border: none;
  border-radius: math.div($base-spacing, 2);
  cursor: pointer;
  font-size: math.div($base-font-size, 0.875);

  &:hover {
    background-color: darken($error-color, 10%);
  }

  &:disabled {
    background-color: lighten($error-color, 30%);
    cursor: not-allowed;
  }
}

// Стили для планшетной версии
@media screen and (max-width: 1024px) {
  .employeeSelectorContainer {
    padding: math.div($base-spacing, 2);
  }

  .employeeSelectorLabel {
    font-size: math.div($base-font-size, 0.675);
  }

  .employeeSelectorSelect {
    font-size: math.div($base-font-size, 0.675);
  }

  .deleteButton {
    padding: math.div($base-spacing, 0.5) math.div($base-spacing, 1);
    font-size: math.div($base-font-size, 0.875);
  }
}

// Стили для мобильной версии
@media screen and (max-width: 768px) {
  .employeeSelectorContainer {
    padding: math.div($base-spacing, 1.5);
  }

  .employeeSelectorLabel {
    font-size: math.div($base-font-size, 0.775);
  }

  .employeeSelectorSelect {
    font-size: math.div($base-font-size, 0.75);
  }

  .deleteButton {
    padding: math.div($base-spacing, 0.5) math.div($base-spacing, 1);
    font-size: math.div($base-font-size, 0.75);
  }
}
