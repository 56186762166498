@use "sass:math";
@import "../../styles/variables";

.authPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f3f4f6;
  padding: math.div($base-spacing, 2);
}

.formContainer {
  width: 100%;
  max-width: 360px;
  padding: math.div($base-spacing, 2.5);
  background-color: #ffffff;
  border-radius: math.div($base-spacing, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: math.div($base-font-size, 1.5);
  color: #333;
  margin-bottom: math.div($base-spacing, 2.5);
}

.tabs {
  display: flex;
  margin-bottom: math.div($base-spacing, 2.5);
}

.tabs button {
  flex: 1;
  padding: math.div($base-spacing, 1.25);
  font-size: $base-font-size;
  background-color: #ececec;
  border: none;
  border-radius: math.div($base-spacing, 2);
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.tabs button:hover {
  background-color: #5e77e5;
}

.activeTab {
  background-color: $primary-color;
  color: #ffffff;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .formContainer {
    padding: math.div($base-spacing, 2);
  }

  .tabs button {
    font-size: math.div($base-font-size, 0.875);
    padding: math.div($base-spacing, 1);
  }

  h1 {
    font-size: math.div($base-font-size, 1.25);
  }
}
