@use "sass:math";
@import "../../styles/variables";

.catamaranManagerContainer {
  max-width: 600px;
  margin: 0 auto;
  padding: math.div($base-spacing, 2.5);
  border: 1px solid darken($secondary-color, 10%);
  border-radius: math.div($base-spacing, 1);
  background-color: $secondary-color;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-family: $base-font-family;
}

.catamaranInput {
  width: 100%;
  margin-bottom: math.div($base-spacing, 1);
  padding: math.div($base-spacing, 1);
  border: 1px solid darken($primary-color, 20%);
  border-radius: math.div($base-spacing, 2);
  font-size: $base-font-size;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;

  &:focus {
    border-color: $primary-color;
    box-shadow: 0 0 5px rgba($primary-color, 0.5);
    outline: none;
  }
}

.catamaranButton {
  width: 100%;
  padding: math.div($base-spacing, 1);
  margin-top: math.div($base-spacing, 1);
  background-color: $success-color;
  color: white;
  border: none;
  border-radius: math.div($base-spacing, 2);
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($success-color, 10%);
  }
}

.catamaranList {
  list-style: none;
  padding: 0;
  margin-top: math.div($base-spacing, 2);
}

.catamaranItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: math.div($base-spacing, 1);
  margin-top: math.div($base-spacing, 1);
  border-bottom: 1px solid darken($secondary-color, 15%);
  border-radius: math.div($base-spacing, 2);
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.catamaranItem:last-child {
  border-bottom: none;
}

.catamaranItem button {
  padding: math.div($base-spacing, 1);
  font-size: math.div($base-font-size, 0.875);
  background-color: $error-color;
  color: white;
  border: none;
  border-radius: math.div($base-spacing, 2);
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken($error-color, 10%);
  }
}

// Стили для планшетной версии
@media screen and (max-width: 1024px) {
  .catamaranManagerContainer {
    max-width: 80%;
  }

  .catamaranButton,
  .catamaranInput {
    font-size: math.div($base-font-size, 0.875);
  }
}

// Стили для мобильной версии
@media screen and (max-width: 768px) {
  .catamaranManagerContainer {
    max-width: 90%;
    padding: math.div($base-spacing, 1.5);
  }

  .catamaranButton,
  .catamaranInput {
    font-size: math.div($base-font-size, 0.75);
  }
}
